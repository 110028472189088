define('@fortawesome/pro-light-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faTrashCan = {
    prefix: 'fal',
    iconName: 'trash-can',
    icon: [448, 512, [61460, "trash-alt"], "f2ed", "M164.2 39.5L148.9 64H299.1L283.8 39.5c-2.9-4.7-8.1-7.5-13.6-7.5H177.7c-5.5 0-10.6 2.8-13.6 7.5zM311 22.6L336.9 64H384h32 16c8.8 0 16 7.2 16 16s-7.2 16-16 16H416V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V96H16C7.2 96 0 88.8 0 80s7.2-16 16-16H32 64h47.1L137 22.6C145.8 8.5 161.2 0 177.7 0h92.5c16.6 0 31.9 8.5 40.7 22.6zM64 96V432c0 26.5 21.5 48 48 48H336c26.5 0 48-21.5 48-48V96H64zm80 80V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16zm96 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16zm96 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16z"]
  };
  var faFolderArrowDown = {
    prefix: 'fal',
    iconName: 'folder-arrow-down',
    icon: [512, 512, ["folder-download"], "e053", "M32 96c0-17.7 14.3-32 32-32H188.1c8.5 0 16.6 3.4 22.6 9.4l35.9 35.9c12 12 28.3 18.7 45.3 18.7H448c17.7 0 32 14.3 32 32V416c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V96zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H291.9c-8.5 0-16.6-3.4-22.6-9.4L233.4 50.7c-12-12-28.3-18.7-45.3-18.7H64zM272 208c0-8.8-7.2-16-16-16s-16 7.2-16 16V329.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l80 80c6.2 6.2 16.4 6.2 22.6 0l80-80c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L272 329.4V208z"]
  };
  var faPaste = {
    prefix: 'fal',
    iconName: 'paste',
    icon: [512, 512, ["file-clipboard"], "f0ea", "M136.5 51.2C138.7 40.2 148.4 32 160 32s21.3 8.2 23.5 19.2C185 58.6 191.6 64 199.2 64H208c8.8 0 16 7.2 16 16V96H96V80c0-8.8 7.2-16 16-16h8.8c7.6 0 14.2-5.4 15.7-12.8zM160 0c-22.3 0-41.6 13.1-50.6 32H64C28.7 32 0 60.7 0 96V384c0 35.3 28.7 64 64 64H192V416H64c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32h2.7C65 69 64 74.4 64 80V96c0 17.7 14.3 32 32 32H208c11.5-15.4 28.5-26.4 48-30.4c0-.5 0-1.1 0-1.6V80c0-5.6-1-11-2.7-16H256c17.7 0 32 14.3 32 32h32c0-35.3-28.7-64-64-64H210.6c-9-18.9-28.3-32-50.6-32zM448 480H288c-17.7 0-32-14.3-32-32V192c0-17.7 14.3-32 32-32H396.1c4.2 0 8.3 1.7 11.3 4.7l67.9 67.9c3 3 4.7 7.1 4.7 11.3V448c0 17.7-14.3 32-32 32zm49.9-270.1l-67.9-67.9c-9-9-21.2-14.1-33.9-14.1H288c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V243.9c0-12.7-5.1-24.9-14.1-33.9z"]
  };
  var faArrowDownToSquare = {
    prefix: 'fal',
    iconName: 'arrow-down-to-square',
    icon: [512, 512, [], "e096", "M267.8 330.9l96-104c6-6.5 5.6-16.6-.9-22.6s-16.6-5.6-22.6 .9L272 279.1V16c0-8.8-7.2-16-16-16s-16 7.2-16 16V279.1l-68.2-73.9c-6-6.5-16.1-6.9-22.6-.9s-6.9 16.1-.9 22.6l96 104c3 3.3 7.3 5.1 11.8 5.1s8.7-1.9 11.8-5.1zM64 32C28.7 32 0 60.7 0 96V448c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H400c-8.8 0-16 7.2-16 16s7.2 16 16 16h48c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32h48c8.8 0 16-7.2 16-16s-7.2-16-16-16H64z"]
  };
  var faListMusic = {
    prefix: 'fal',
    iconName: 'list-music',
    icon: [512, 512, [], "f8c9", "M489.1 0C501.8 0 512 10.2 512 22.9V136c0 6.9-4.5 13.1-11.1 15.2L384 188.9V432c0 44.2-50.1 80-112 80s-112-35.8-112-80s50.1-80 112-80c31.3 0 59.7 9.2 80 24V56c0-6.9 4.4-13 10.9-15.2L481.9 1.2c2.3-.8 4.8-1.2 7.2-1.2zM332.6 401.5C318.3 391.3 297 384 272 384s-46.3 7.3-60.6 17.5C197.1 411.7 192 422.9 192 432s5.1 20.3 19.4 30.5C225.7 472.7 247 480 272 480s46.3-7.3 60.6-17.5C346.9 452.3 352 441.1 352 432s-5.1-20.3-19.4-30.5zM384 155.3l96-30.9V35.5l-96 32v87.8zM16 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H16C7.2 96 0 88.8 0 80s7.2-16 16-16zm0 128H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H16c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 128H144c8.8 0 16 7.2 16 16s-7.2 16-16 16H16c-8.8 0-16-7.2-16-16s7.2-16 16-16z"]
  };
  var faVolumeLow = {
    prefix: 'fal',
    iconName: 'volume-low',
    icon: [448, 512, [128264, "volume-down"], "f027", "M151.6 188L288 66.7V445.3L151.6 324c-2.9-2.6-6.7-4-10.6-4H56c-13.3 0-24-10.7-24-24V216c0-13.3 10.7-24 24-24h85c3.9 0 7.7-1.4 10.6-4zM290.2 32c-7.3 0-14.3 2.7-19.8 7.5L134.9 160H56c-30.9 0-56 25.1-56 56v80c0 30.9 25.1 56 56 56h78.9L270.4 472.5l10.6-12-10.6 12c5.5 4.8 12.5 7.5 19.8 7.5c16.5 0 29.8-13.3 29.8-29.8V61.8C320 45.3 306.7 32 290.2 32zM409.6 179.2c-7.1-5.3-17.1-3.9-22.4 3.2s-3.9 17.1 3.2 22.4C406 216.5 416 235.1 416 256s-10 39.5-25.6 51.2c-7.1 5.3-8.5 15.3-3.2 22.4s15.3 8.5 22.4 3.2C432.9 315.3 448 287.4 448 256s-15.1-59.3-38.4-76.8z"]
  };
  var faBullseye = {
    prefix: 'fal',
    iconName: 'bullseye',
    icon: [512, 512, [], "f140", "M480 256A224 224 0 1 0 32 256a224 224 0 1 0 448 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM256 384a128 128 0 1 0 0-256 128 128 0 1 0 0 256zm0-288a160 160 0 1 1 0 320 160 160 0 1 1 0-320zm0 192a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm0-96a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"]
  };
  var faFolder = {
    prefix: 'fal',
    iconName: 'folder',
    icon: [512, 512, [128193, 128447, 61716, "folder-blank"], "f07b", "M64 64C46.3 64 32 78.3 32 96V416c0 17.7 14.3 32 32 32H448c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H291.9c-17 0-33.3-6.7-45.3-18.7L210.7 73.4c-6-6-14.1-9.4-22.6-9.4H64zM0 96C0 60.7 28.7 32 64 32H188.1c17 0 33.3 6.7 45.3 18.7l35.9 35.9c6 6 14.1 9.4 22.6 9.4H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"]
  };
  var faShuffle = {
    prefix: 'fal',
    iconName: 'shuffle',
    icon: [512, 512, [128256, "random"], "f074", "M427.3 36.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L457.4 112H400 344c-20.1 0-39.1 9.5-51.2 25.6L244 202.7l20 26.7 54.4-72.5c6-8.1 15.5-12.8 25.6-12.8h56 57.4l-52.7 52.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80zM124.8 361.6c-3 4-7.8 6.4-12.8 6.4H16c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c15.1 0 29.3-7.1 38.4-19.2L204 309.3l-20-26.7-59.2 78.9zM404.7 475.3c6.2 6.2 16.4 6.2 22.6 0l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L457.4 368H400 344c-10.1 0-19.6-4.7-25.6-12.8l-168-224C141.3 119.1 127.1 112 112 112H16c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c5 0 9.8 2.4 12.8 6.4l168 224C304.9 390.5 323.9 400 344 400h56 57.4l-52.7 52.7c-6.2 6.2-6.2 16.4 0 22.6z"]
  };
  var faPianoKeyboard = {
    prefix: 'fal',
    iconName: 'piano-keyboard',
    icon: [576, 512, [127929], "f8d5", "M448 224v64c0 8.8-7.2 16-16 16s-16-7.2-16-16V224h32zM416 333.3V416H304V333.3c18.6-6.6 32-24.4 32-45.3V224h48v64c0 20.9 13.4 38.7 32 45.3zM448 416V333.3c18.6-6.6 32-24.4 32-45.3V224h64V384c0 17.7-14.3 32-32 32H448zm-16 32h80c35.3 0 64-28.7 64-64V224 208 192 128c0-35.3-28.7-64-64-64H64C28.7 64 0 92.7 0 128v64 16 16V384c0 35.3 28.7 64 64 64h80H288 432zM272 416H160V333.3c18.6-6.6 32-24.4 32-45.3V224h48v64c0 20.9 13.4 38.7 32 45.3V416zm-144 0H64c-17.7 0-32-14.3-32-32V224H96v64c0 20.9 13.4 38.7 32 45.3V416zm16-112c-8.8 0-16-7.2-16-16V224h32v64c0 8.8-7.2 16-16 16zm144 0c-8.8 0-16-7.2-16-16V224h32v64c0 8.8-7.2 16-16 16zM464 192H400 320 256 176 112 32V128c0-17.7 14.3-32 32-32H512c17.7 0 32 14.3 32 32v64H464z"]
  };
  var faFolderOpen = {
    prefix: 'fal',
    iconName: 'folder-open',
    icon: [576, 512, [128194, 128449, 61717], "f07c", "M448 160v32h32V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384h80c19.6 0 37.3-11.9 44.6-30.2l64-160c5.9-14.8 4.1-31.5-4.8-44.7S543.9 224 528 224H128c-19.6 0-37.3 11.9-44.6 30.2L32 382.8V96c0-17.7 14.3-32 32-32H181.5c8.5 0 16.6 3.4 22.6 9.4l22.6-22.6L204.1 73.4l26.5 26.5c18 18 42.4 28.1 67.9 28.1H416c17.7 0 32 14.3 32 32zM384 448H64c-5.3 0-10.3-2.6-13.2-7s-3.6-10-1.6-14.9l64-160c2.4-6.1 8.3-10.1 14.9-10.1H528c5.3 0 10.3 2.6 13.2 7s3.6 10 1.6 14.9l-64 160C476.4 444 470.5 448 464 448H384z"]
  };
  var faVolume = {
    prefix: 'fal',
    iconName: 'volume',
    icon: [576, 512, [128265, "volume-medium"], "f6a8", "M320 66.7L183.6 188c-2.9 2.6-6.7 4-10.6 4H88c-13.3 0-24 10.7-24 24v80c0 13.3 10.7 24 24 24h85c3.9 0 7.7 1.4 10.6 4L320 445.3V66.7zM302.4 39.5c5.5-4.8 12.5-7.5 19.8-7.5C338.7 32 352 45.3 352 61.8V450.2c0 16.5-13.3 29.8-29.8 29.8c-7.3 0-14.3-2.7-19.8-7.5l10.6-12-10.6 12L166.9 352H88c-30.9 0-56-25.1-56-56V216c0-30.9 25.1-56 56-56h78.9L302.4 39.5zM419.2 182.4c5.3-7.1 15.3-8.5 22.4-3.2C464.9 196.7 480 224.6 480 256s-15.1 59.3-38.4 76.8c-7.1 5.3-17.1 3.9-22.4-3.2s-3.9-17.1 3.2-22.4C438 295.5 448 276.9 448 256s-10-39.5-25.6-51.2c-7.1-5.3-8.5-15.3-3.2-22.4zm87-74.5C548.8 143.1 576 196.4 576 256s-27.2 112.9-69.8 148.1c-6.8 5.6-16.9 4.7-22.5-2.1s-4.7-16.9 2.1-22.5C521.4 350.1 544 305.7 544 256s-22.6-94.1-58.2-123.4c-6.8-5.6-7.8-15.7-2.1-22.5s15.7-7.8 22.5-2.1z"]
  };
  var faVolumeHigh = {
    prefix: 'fal',
    iconName: 'volume-high',
    icon: [640, 512, [128266, "volume-up"], "f028", "M151.6 188L288 66.7V445.3L151.6 324c-2.9-2.6-6.7-4-10.6-4H56c-13.3 0-24-10.7-24-24V216c0-13.3 10.7-24 24-24h85c3.9 0 7.7-1.4 10.6-4zM290.2 32c-7.3 0-14.3 2.7-19.8 7.5L134.9 160H56c-30.9 0-56 25.1-56 56v80c0 30.9 25.1 56 56 56h78.9L270.4 472.5l10.6-12-10.6 12c5.5 4.8 12.5 7.5 19.8 7.5c16.5 0 29.8-13.3 29.8-29.8V61.8C320 45.3 306.7 32 290.2 32zM409.6 179.2c-7.1-5.3-17.1-3.9-22.4 3.2s-3.9 17.1 3.2 22.4C406 216.5 416 235.1 416 256s-10 39.5-25.6 51.2c-7.1 5.3-8.5 15.3-3.2 22.4s15.3 8.5 22.4 3.2C432.9 315.3 448 287.4 448 256s-15.1-59.3-38.4-76.8zm42-69.2c-5.6 6.8-4.7 16.9 2.1 22.5C489.4 161.9 512 206.3 512 256s-22.6 94.1-58.2 123.4c-6.8 5.6-7.8 15.7-2.1 22.5s15.7 7.8 22.5 2.1C516.8 368.9 544 315.6 544 256s-27.2-112.9-69.8-148.1c-6.8-5.6-16.9-4.7-22.5 2.1zm86.7-73.6c-6.7-5.7-16.8-4.9-22.6 1.8s-4.9 16.8 1.8 22.6c55.3 47 90.4 117 90.4 195.2s-35.1 148.2-90.4 195.2c-6.7 5.7-7.6 15.8-1.8 22.6s15.8 7.6 22.6 1.8C600.5 422.8 640 344 640 256s-39.5-166.8-101.6-219.6z"]
  };
  var faArrowsMinimize = {
    prefix: 'fal',
    iconName: 'arrows-minimize',
    icon: [512, 512, ["compress-arrows"], "e0a5", "M36.7 36.7c6.2-6.2 16.4-6.2 22.6 0L192 169.4V64c0-8.8 7.2-16 16-16s16 7.2 16 16V208c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16s7.2-16 16-16H169.4L36.7 59.3c-6.2-6.2-6.2-16.4 0-22.6zm416 0c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6L342.6 192H448c8.8 0 16 7.2 16 16s-7.2 16-16 16H304c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16s16 7.2 16 16V169.4L452.7 36.7zM48 304c0-8.8 7.2-16 16-16H208c8.8 0 16 7.2 16 16V448c0 8.8-7.2 16-16 16s-16-7.2-16-16V342.6L59.3 475.3c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L169.4 320H64c-8.8 0-16-7.2-16-16zm240 0c0-8.8 7.2-16 16-16H448c8.8 0 16 7.2 16 16s-7.2 16-16 16H342.6L475.3 452.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L320 342.6V448c0 8.8-7.2 16-16 16s-16-7.2-16-16V304z"]
  };
  var faFloppyDisk = {
    prefix: 'fal',
    iconName: 'floppy-disk',
    icon: [448, 512, [128190, 128426, "save"], "f0c7", "M32 96c0-17.7 14.3-32 32-32l0 96c0 17.7 14.3 32 32 32H288c17.7 0 32-14.3 32-32V65.8c4.5 1.6 8.7 4.2 12.1 7.6l74.5 74.5c6 6 9.4 14.1 9.4 22.6V416c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V96zM96 64H288v96H96V64zM0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V170.5c0-17-6.7-33.3-18.7-45.3L354.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96zM272 320a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-48-80a80 80 0 1 0 0 160 80 80 0 1 0 0-160z"]
  };
  var faArrowRotateRight = {
    prefix: 'fal',
    iconName: 'arrow-rotate-right',
    icon: [512, 512, [8635, "arrow-right-rotate", "arrow-rotate-forward", "redo"], "f01e", "M464 192c8.8 0 16-7.2 16-16V48c0-8.8-7.2-16-16-16s-16 7.2-16 16v92.6C408.8 75.5 337.5 32 256 32C132.3 32 32 132.3 32 256s100.3 224 224 224c79.9 0 150-41.8 189.7-104.8c6.6-10.4-1.4-23.2-13.7-23.2c-6 0-11.4 3.2-14.7 8.2C383.1 413 323.6 448 256 448C150 448 64 362 64 256S150 64 256 64c71.1 0 133.1 38.6 166.3 96H336c-8.8 0-16 7.2-16 16s7.2 16 16 16H464z"]
  };
  var faWaveform = {
    prefix: 'fal',
    iconName: 'waveform',
    icon: [640, 512, [], "f8f1", "M320 0c8 0 14.8 5.9 15.9 13.9l49.3 369.5L432.2 77.6c1.2-7.9 8-13.6 16-13.6s14.6 6 15.7 13.8l34.6 254 29.8-158.8c1.4-7.3 7.6-12.7 15-13s14.1 4.5 16.1 11.6L588.1 272H624c8.8 0 16 7.2 16 16s-7.2 16-16 16H576c-7.1 0-13.4-4.7-15.4-11.6l-13.4-46.8L511.7 434.9c-1.4 7.7-8.3 13.2-16.1 13s-14.4-6.1-15.5-13.8L447 191.4l-47.2 307c-1.2 7.9-8 13.6-16 13.6s-14.6-6-15.7-13.9L320 137.1 271.9 498.1c-1.1 7.9-7.7 13.8-15.7 13.9s-14.8-5.7-16-13.6L193 191.4 159.9 434.2c-1.1 7.8-7.6 13.6-15.5 13.8s-14.7-5.3-16.1-13L92.8 245.6 79.4 292.4c-2 6.9-8.2 11.6-15.4 11.6H16c-8.8 0-16-7.2-16-16s7.2-16 16-16H51.9L80.6 171.6c2-7.1 8.7-11.9 16.1-11.6s13.6 5.7 15 13l29.8 158.8 34.6-254c1.1-7.9 7.8-13.8 15.7-13.8s14.7 5.7 16 13.6l47.1 305.9L304.1 13.9C305.2 5.9 312 0 320 0z"]
  };
  var faArrowDownAZ = {
    prefix: 'fal',
    iconName: 'arrow-down-a-z',
    icon: [576, 512, ["sort-alpha-asc", "sort-alpha-down"], "f15d", "M267.3 379.3l-96 96c-6.2 6.2-16.4 6.2-22.6 0l-96-96c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L144 425.4V48c0-8.8 7.2-16 16-16s16 7.2 16 16V425.4l68.7-68.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6zm163-338.5l63.9 127.7c.1 .2 .2 .4 .3 .6l15.8 31.7c4 7.9 .7 17.5-7.2 21.5s-17.5 .7-21.5-7.2L470.1 192H361.9l-11.6 23.2c-4 7.9-13.6 11.1-21.5 7.2s-11.1-13.6-7.2-21.5l15.8-31.7c.1-.2 .2-.4 .3-.6L401.7 40.8c2.7-5.4 8.2-8.8 14.3-8.8s11.6 3.4 14.3 8.8zM416 83.8L377.9 160h76.2L416 83.8zM352 288H480c6.2 0 11.8 3.5 14.4 9.1s1.9 12.1-1.9 16.9L385.3 448H480c8.8 0 16 7.2 16 16s-7.2 16-16 16H352c-6.2 0-11.8-3.5-14.4-9.1s-1.9-12.1 1.9-16.9L446.7 320H352c-8.8 0-16-7.2-16-16s7.2-16 16-16z"]
  };
  var faArrowRotateLeft = {
    prefix: 'fal',
    iconName: 'arrow-rotate-left',
    icon: [512, 512, [8634, "arrow-left-rotate", "arrow-rotate-back", "arrow-rotate-backward", "undo"], "f0e2", "M48 192c-8.8 0-16-7.2-16-16V48c0-8.8 7.2-16 16-16s16 7.2 16 16v92.6C103.2 75.5 174.5 32 256 32c123.7 0 224 100.3 224 224s-100.3 224-224 224c-79.9 0-150-41.8-189.7-104.8C59.8 364.8 67.7 352 80 352c6 0 11.4 3.2 14.7 8.2C128.9 413 188.4 448 256 448c106 0 192-86 192-192s-86-192-192-192c-71.1 0-133.1 38.6-166.3 96H176c8.8 0 16 7.2 16 16s-7.2 16-16 16H48z"]
  };
  var faFolderMusic = {
    prefix: 'fal',
    iconName: 'folder-music',
    icon: [512, 512, [], "e18d", "M32 96c0-17.7 14.3-32 32-32H188.1c8.5 0 16.6 3.4 22.6 9.4l35.9 35.9c12 12 28.3 18.7 45.3 18.7H448c17.7 0 32 14.3 32 32V416c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V96zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H291.9c-8.5 0-16.6-3.4-22.6-9.4L233.4 50.7c-12-12-28.3-18.7-45.3-18.7H64zM345.1 178.8c-4.3-3-9.8-3.7-14.7-1.8l-128 48c-6.2 2.3-10.4 8.3-10.4 15v90.5c-5-1.6-10.4-2.5-16-2.5c-26.5 0-48 19.7-48 44s21.5 44 48 44s48-19.7 48-44c0-.8 0-1.6-.1-2.5c0-.5 .1-1 .1-1.5V251.1l96-36v83.4c-5-1.6-10.4-2.5-16-2.5c-26.5 0-48 19.7-48 44s21.5 44 48 44s48-19.7 48-44c0-.8 0-1.6-.1-2.5c0-.5 .1-1 .1-1.5V192c0-5.2-2.6-10.2-6.9-13.2zM320 340c0 4.1-4.5 12-16 12s-16-7.9-16-12s4.5-12 16-12s16 7.9 16 12zM192 372c0 4.1-4.5 12-16 12s-16-7.9-16-12s4.5-12 16-12s16 7.9 16 12z"]
  };
  var faArrowsMaximize = {
    prefix: 'fal',
    iconName: 'arrows-maximize',
    icon: [512, 512, ["expand-arrows"], "f31d", "M336 32c-8.8 0-16 7.2-16 16s7.2 16 16 16h89.4L256 233.4 86.6 64H176c8.8 0 16-7.2 16-16s-7.2-16-16-16H48c-8.8 0-16 7.2-16 16V176c0 8.8 7.2 16 16 16s16-7.2 16-16V86.6L233.4 256 64 425.4V336c0-8.8-7.2-16-16-16s-16 7.2-16 16V464c0 8.8 7.2 16 16 16H176c8.8 0 16-7.2 16-16s-7.2-16-16-16H86.6L256 278.6 425.4 448H336c-8.8 0-16 7.2-16 16s7.2 16 16 16H464c8.8 0 16-7.2 16-16V336c0-8.8-7.2-16-16-16s-16 7.2-16 16v89.4L278.6 256 448 86.6V176c0 8.8 7.2 16 16 16s16-7.2 16-16V48c0-8.8-7.2-16-16-16H336z"]
  };
  var faEllipsisVertical = {
    prefix: 'fal',
    iconName: 'ellipsis-vertical',
    icon: [128, 512, ["ellipsis-v"], "f142", "M64 384a32 32 0 1 0 0 64 32 32 0 1 0 0-64zm0-160a32 32 0 1 0 0 64 32 32 0 1 0 0-64zM96 96A32 32 0 1 0 32 96a32 32 0 1 0 64 0z"]
  };
  var faDownload = {
    prefix: 'fal',
    iconName: 'download',
    icon: [512, 512, [], "f019", "M272 16c0-8.8-7.2-16-16-16s-16 7.2-16 16V329.4L139.3 228.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l128 128c6.2 6.2 16.4 6.2 22.6 0l128-128c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L272 329.4V16zM140.1 320H64c-35.3 0-64 28.7-64 64v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V384c0-35.3-28.7-64-64-64H371.9l-32 32H448c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V384c0-17.7 14.3-32 32-32H172.1l-32-32zM432 416a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z"]
  };
  var faArrowUpWideShort = {
    prefix: 'fal',
    iconName: 'arrow-up-wide-short',
    icon: [576, 512, ["sort-amount-up"], "f161", "M139.3 36.7c-6.2-6.2-16.4-6.2-22.6 0l-96 96c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L112 86.6V464c0 8.8 7.2 16 16 16s16-7.2 16-16V86.6l68.7 68.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-96-96zM304 464h64c8.8 0 16-7.2 16-16s-7.2-16-16-16H304c-8.8 0-16 7.2-16 16s7.2 16 16 16zm0-128H432c8.8 0 16-7.2 16-16s-7.2-16-16-16H304c-8.8 0-16 7.2-16 16s7.2 16 16 16zm0-128H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H304c-8.8 0-16 7.2-16 16s7.2 16 16 16zm0-128H560c8.8 0 16-7.2 16-16s-7.2-16-16-16H304c-8.8 0-16 7.2-16 16s7.2 16 16 16z"]
  };
  var faStop = {
    prefix: 'fal',
    iconName: 'stop',
    icon: [384, 512, [9209], "f04d", "M320 96c17.7 0 32 14.3 32 32V384c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V128c0-17.7 14.3-32 32-32H320zM64 64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64z"]
  };
  var faSun = {
    prefix: 'fal',
    iconName: 'sun',
    icon: [512, 512, [9728], "f185", "M371.1 13.1c-1-5.3-4.6-9.8-9.6-11.9s-10.7-1.5-15.2 1.6L256 65.1 165.7 2.8c-4.5-3.1-10.2-3.7-15.2-1.6s-8.6 6.6-9.6 11.9L121 121 13.1 140.8c-5.3 1-9.8 4.6-11.9 9.6s-1.5 10.7 1.6 15.2L65.1 256 2.8 346.3c-3.1 4.5-3.7 10.2-1.6 15.2s6.6 8.6 11.9 9.6L121 391l19.8 107.9c1 5.3 4.6 9.8 9.6 11.9s10.7 1.5 15.2-1.6L256 446.9l90.3 62.3c4.5 3.1 10.2 3.7 15.2 1.6s8.6-6.6 9.6-11.9L391 391l107.9-19.8c5.3-1 9.8-4.6 11.9-9.6s1.5-10.7-1.6-15.2L446.9 256l62.3-90.3c3.1-4.5 3.7-10.2 1.6-15.2s-6.6-8.6-11.9-9.6L391 121 371.1 13.1zM265.1 97.7l79.1-54.5 17.4 94.5c1.2 6.5 6.3 11.6 12.8 12.8l94.5 17.4-54.5 79.1c-3.8 5.5-3.8 12.7 0 18.2l54.5 79.1-94.5 17.4c-6.5 1.2-11.6 6.3-12.8 12.8l-17.4 94.5-79.1-54.5c-5.5-3.8-12.7-3.8-18.2 0l-79.1 54.5-17.4-94.5c-1.2-6.5-6.3-11.6-12.8-12.8L43.2 344.1l54.5-79.1c3.8-5.5 3.8-12.7 0-18.2L43.2 167.8l94.5-17.4c6.5-1.2 11.6-6.3 12.8-12.8l17.4-94.5 79.1 54.5c5.5 3.8 12.7 3.8 18.2 0zM256 384a128 128 0 1 0 0-256 128 128 0 1 0 0 256zM160 256a96 96 0 1 1 192 0 96 96 0 1 1 -192 0z"]
  };
  var faArrowsRepeat = {
    prefix: 'fal',
    iconName: 'arrows-repeat',
    icon: [512, 512, ["repeat-alt"], "f364", "M15.2 272C6.4 271.5-.4 264 0 255.2l.4-8C5.1 153.5 82.4 80 176.2 80H390.6L333.2 27.8c-6.5-5.9-7-16.1-1.1-22.6s16.1-7 22.6-1.1l88 80c3.3 3 5.2 7.3 5.2 11.8s-1.9 8.8-5.2 11.8l-88 80c-6.5 5.9-16.7 5.5-22.6-1.1s-5.5-16.7 1.1-22.6L390.6 112H176.2c-76.7 0-140 60.2-143.8 136.8l-.4 8c-.4 8.8-8 15.6-16.8 15.2zm481.6-32c8.8 .4 15.6 8 15.2 16.8l-.4 8c-4.7 93.7-82 167.2-175.8 167.2H121.4l57.4 52.2c6.5 5.9 7 16.1 1.1 22.6s-16.1 7-22.6 1.1l-88-80c-3.3-3-5.2-7.3-5.2-11.8s1.9-8.8 5.2-11.8l88-80c6.5-5.9 16.7-5.5 22.6 1.1s5.5 16.7-1.1 22.6L121.4 400H335.8c76.7 0 140-60.2 143.8-136.8l.4-8c.4-8.8 8-15.6 16.8-15.2z"]
  };
  var faSquareQuestion = {
    prefix: 'fal',
    iconName: 'square-question',
    icon: [448, 512, ["question-square"], "f2fd", "M64 64C46.3 64 32 78.3 32 96V416c0 17.7 14.3 32 32 32H384c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm136.7 73.8c6.5-24.6 28.7-41.8 54.2-41.8H248c35.5 0 64 29 64 64.3c0 24-13.4 46.2-34.9 57.2L240 268.3V288c0 8.8-7.2 16-16 16s-16-7.2-16-16V258.5c0-6 3.4-11.5 8.7-14.3l45.8-23.4c10.7-5.4 17.5-16.6 17.5-28.7c0-17.8-14.4-32.3-32-32.3H190.9c-10.9 0-20.5 7.4-23.2 17.9l-.2 .7c-2.2 8.5-11 13.7-19.5 11.4s-13.7-11-11.4-19.5l.2-.7zM200 352a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"]
  };
  var faDrum = {
    prefix: 'fal',
    iconName: 'drum',
    icon: [512, 512, [129345], "f569", "M509.6 39.5c4.7 7.5 2.4 17.4-5.1 22l-86.3 53.9C475.4 133 512 162.8 512 208V368c0 22.6-13.6 41.6-32.3 56.2c-18.8 14.7-44.9 26.8-75.1 35.9C362.8 472.7 311.3 480 256 480s-106.8-7.3-148.6-19.9c-30.3-9.1-56.3-21.2-75.1-35.9C13.6 409.6 0 390.6 0 368V208C0 128 114.6 96 256 96c42.7 0 82.9 2.9 118.3 9.2L487.5 34.4c7.5-4.7 17.4-2.4 22 5.1zM332 131.6c-23.5-2.4-49-3.6-76-3.6c-69 0-128.5 7.9-169.4 23.8C45.3 167.9 32 187.6 32 208c0 11.2 11.3 31.5 55.7 50.6C129 276.5 188.5 288 256 288s127-11.5 168.3-29.4C468.7 239.5 480 219.2 480 208c0-20.4-13.3-40.1-54.6-56.2c-13.1-5.1-28.2-9.4-44.8-12.8L248.5 221.6c-7.5 4.7-17.4 2.4-22-5.1s-2.4-17.4 5.1-22L332 131.6zM480 263.2c-16.2 12.8-38.1 24-64 32.9l0 126.4c18.3-7 33.2-15 44-23.5c14.8-11.6 20-22.2 20-31V263.2zm-96 42.3c-33.3 8.2-71.4 13.3-112 14.3v128c41.5-1.1 79.7-6.6 112-15.1l0-127.2zM240 319.8c-40.6-1.1-78.7-6.1-112-14.3V432.7c32.3 8.5 70.5 14 112 15.1v-128zM96 296.1c-25.9-8.9-47.8-20.1-64-32.9V368c0 8.8 5.3 19.5 20 31c10.8 8.5 25.6 16.5 44 23.5V296.1z"]
  };
  var faPlay = {
    prefix: 'fal',
    iconName: 'play',
    icon: [384, 512, [9654], "f04b", "M56.3 66.3c-4.9-3-11.1-3.1-16.2-.3s-8.2 8.2-8.2 14V432c0 5.8 3.1 11.1 8.2 14s11.2 2.7 16.2-.3l288-176c4.8-2.9 7.7-8.1 7.7-13.7s-2.9-10.7-7.7-13.7l-288-176zM24.5 38.1C39.7 29.6 58.2 30 73 39L361 215c14.3 8.7 23 24.2 23 41s-8.7 32.2-23 41L73 473c-14.8 9.1-33.4 9.4-48.5 .9S0 449.4 0 432V80C0 62.6 9.4 46.6 24.5 38.1z"]
  };
  var faMagnifyingGlass = {
    prefix: 'fal',
    iconName: 'magnifying-glass',
    icon: [512, 512, [128269, "search"], "f002", "M384 208A176 176 0 1 0 32 208a176 176 0 1 0 352 0zM343.3 366C307 397.2 259.7 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 51.7-18.8 99-50 135.3L507.3 484.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L343.3 366z"]
  };
  var faCopy = {
    prefix: 'fal',
    iconName: 'copy',
    icon: [448, 512, [], "f0c5", "M384 352H224c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H332.1c4.2 0 8.3 1.7 11.3 4.7l67.9 67.9c3 3 4.7 7.1 4.7 11.3V320c0 17.7-14.3 32-32 32zM433.9 81.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H224c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H224c35.3 0 64-28.7 64-64V416H256v32c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V192c0-17.7 14.3-32 32-32h64V128H64z"]
  };
  var faPlus = {
    prefix: 'fal',
    iconName: 'plus',
    icon: [448, 512, [10133, 61543, "add"], "2b", "M240 64c0-8.8-7.2-16-16-16s-16 7.2-16 16V240H32c-8.8 0-16 7.2-16 16s7.2 16 16 16H208V448c0 8.8 7.2 16 16 16s16-7.2 16-16V272H416c8.8 0 16-7.2 16-16s-7.2-16-16-16H240V64z"]
  };
  var faXmark = {
    prefix: 'fal',
    iconName: 'xmark',
    icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z"]
  };
  var faMusic = {
    prefix: 'fal',
    iconName: 'music',
    icon: [512, 512, [127925], "f001", "M512 23c0-12.7-10.3-23-23-23c-2.3 0-4.6 .3-6.8 1l-311 95.7C164.6 98.8 160 105 160 112V232 372.4C143 359.7 120.6 352 96 352c-53 0-96 35.8-96 80s43 80 96 80s96-35.8 96-80V246.3l288-88.6V308.4c-17-12.7-39.4-20.4-64-20.4c-53 0-96 35.8-96 80s43 80 96 80s96-35.8 96-80V136.4c0-.2 0-.5 0-.7V23zM480 368c0 21.3-22.9 48-64 48s-64-26.7-64-48s22.9-48 64-48s64 26.7 64 48zM160 432c0 21.3-22.9 48-64 48s-64-26.7-64-48s22.9-48 64-48s64 26.7 64 48zM480 124.2L192 212.8v-89L480 35.2v89z"]
  };
  var faFileMusic = {
    prefix: 'fal',
    iconName: 'file-music',
    icon: [384, 512, [], "f8b6", "M352 448V192H240c-26.5 0-48-21.5-48-48V32H64C46.3 32 32 46.3 32 64V448c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32zm-.5-288c-.7-2.8-2.1-5.4-4.2-7.4L231.4 36.7c-2.1-2.1-4.6-3.5-7.4-4.2V144c0 8.8 7.2 16 16 16H351.5zM0 64C0 28.7 28.7 0 64 0H220.1c12.7 0 24.9 5.1 33.9 14.1L369.9 129.9c9 9 14.1 21.2 14.1 33.9V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM304 232c0-5.2-2.6-10.2-6.9-13.2s-9.8-3.7-14.7-1.8l-128 48c-6.2 2.3-10.4 8.3-10.4 15v90.5c-5-1.6-10.4-2.5-16-2.5c-26.5 0-48 19.7-48 44s21.5 44 48 44s48-19.7 48-44c0-.8 0-1.6-.1-2.5c0-.5 .1-1 .1-1.5V291.1l96-36v83.4c-5-1.6-10.4-2.5-16-2.5c-26.5 0-48 19.7-48 44s21.5 44 48 44s48-19.7 48-44c0-.8 0-1.6-.1-2.5c0-.5 .1-1 .1-1.5V232zM256 392c-11.5 0-16-7.9-16-12s4.5-12 16-12s16 7.9 16 12s-4.5 12-16 12zM128 424c-11.5 0-16-7.9-16-12s4.5-12 16-12s16 7.9 16 12s-4.5 12-16 12z"]
  };
  var faSdCard = {
    prefix: 'fal',
    iconName: 'sd-card',
    icon: [384, 512, [], "f7c2", "M352 64V448c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V154.5c0-8.5 3.4-16.6 9.4-22.6l90.5-90.5c6-6 14.1-9.4 22.6-9.4H320c17.7 0 32 14.3 32 32zm32 384V64c0-35.3-28.7-64-64-64H154.5c-17 0-33.3 6.7-45.3 18.7L18.7 109.3C6.7 121.3 0 137.5 0 154.5V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64zM192 80c0-8.8-7.2-16-16-16s-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16V80zm64 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16V80zm64 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16V80z"]
  };
  var faLinkSlash = {
    prefix: 'fal',
    iconName: 'link-slash',
    icon: [640, 512, ["chain-broken", "chain-slash", "unlink"], "f127", "M25.9 3.4C19-2 8.9-.8 3.4 6.1S-.8 23.1 6.1 28.6l608 480c6.9 5.5 17 4.3 22.5-2.6s4.3-17-2.6-22.5L25.9 3.4zM591.5 256c50-50 50-131 0-181s-131-50-181 0L387.9 97.6c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l22.6-22.6c37.5-37.5 98.3-37.5 135.8 0s37.5 98.3 0 135.8L492 310.3l25.3 20L591.5 256zM414.6 412.2l-33.4-26.3c-26.1 1.3-52.5-8.1-72.5-28c-14.2-14.2-23-31.7-26.4-50.1L249 281.5c-2.4 35.7 10.1 72 37.2 99.1c34.8 34.8 84.6 45.3 128.5 31.7zM331.2 154.1c14.2 14.2 23 31.7 26.4 50.1L391 230.5c2.4-35.7-10.1-72-37.2-99.1c-34.8-34.8-84.6-45.3-128.5-31.7l33.4 26.3c26.1-1.3 52.5 8.1 72.5 28zM148 201.7l-25.3-20L48.5 256c-50 50-50 131 0 181s131 50 181 0l22.6-22.6c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0l-22.6 22.6c-37.5 37.5-98.3 37.5-135.8 0s-37.5-98.3 0-135.8L148 201.7z"]
  };
  var faClone = {
    prefix: 'fal',
    iconName: 'clone',
    icon: [512, 512, [], "f24d", "M64 480H288c17.7 0 32-14.3 32-32V384h32v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h64v32H64c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32zM224 320H448c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H224c-17.7 0-32 14.3-32 32V288c0 17.7 14.3 32 32 32zm-64-32V64c0-35.3 28.7-64 64-64H448c35.3 0 64 28.7 64 64V288c0 35.3-28.7 64-64 64H224c-35.3 0-64-28.7-64-64z"]
  };
  var faTriangleExclamation = {
    prefix: 'fal',
    iconName: 'triangle-exclamation',
    icon: [512, 512, [9888, "exclamation-triangle", "warning"], "f071", "M34.5 420.4c-1.6 2.8-2.5 6-2.5 9.3c0 10.2 8.2 18.4 18.4 18.4H461.6c10.2 0 18.4-8.2 18.4-18.4c0-3.3-.9-6.4-2.5-9.3L276.5 75.8C272.2 68.5 264.4 64 256 64s-16.2 4.5-20.5 11.8L34.5 420.4zM6.9 404.2l201-344.6C217.9 42.5 236.2 32 256 32s38.1 10.5 48.1 27.6l201 344.6c4.5 7.7 6.9 16.5 6.9 25.4c0 27.8-22.6 50.4-50.4 50.4H50.4C22.6 480 0 457.4 0 429.6c0-8.9 2.4-17.7 6.9-25.4zM256 160c8.8 0 16 7.2 16 16V304c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16zM232 384a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"]
  };
  var faPaperPlane = {
    prefix: 'fal',
    iconName: 'paper-plane',
    icon: [512, 512, [61913], "f1d8", "M511.6 36.9c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256C5.5 266.3-.7 277.8 .1 290s8.4 22.9 19.6 27.6L160 376v93c0 23.8 19.3 43 43 43c13.1 0 25.4-5.9 33.6-16.1l52.8-66 .1 0 114.2 47.6c9.1 3.8 19.4 3.2 28-1.6s14.5-13.3 16-23l64-416zm-253 380.2l-47 58.8c-2.1 2.6-5.3 4.1-8.6 4.1c-6.1 0-11-4.9-11-11V389.3l66.6 27.8zm43.1-16.7l-96.6-40.3L474.1 70.5 416 448 301.8 400.4zM450.5 48.8L173.6 347 32 288 450.5 48.8z"]
  };

  exports.faArrowDownAZ = faArrowDownAZ;
  exports.faArrowDownToSquare = faArrowDownToSquare;
  exports.faArrowRotateLeft = faArrowRotateLeft;
  exports.faArrowRotateRight = faArrowRotateRight;
  exports.faArrowUpWideShort = faArrowUpWideShort;
  exports.faArrowsMaximize = faArrowsMaximize;
  exports.faArrowsMinimize = faArrowsMinimize;
  exports.faArrowsRepeat = faArrowsRepeat;
  exports.faBullseye = faBullseye;
  exports.faClone = faClone;
  exports.faCopy = faCopy;
  exports.faDownload = faDownload;
  exports.faDrum = faDrum;
  exports.faEllipsisVertical = faEllipsisVertical;
  exports.faFileMusic = faFileMusic;
  exports.faFloppyDisk = faFloppyDisk;
  exports.faFolder = faFolder;
  exports.faFolderArrowDown = faFolderArrowDown;
  exports.faFolderMusic = faFolderMusic;
  exports.faFolderOpen = faFolderOpen;
  exports.faLinkSlash = faLinkSlash;
  exports.faListMusic = faListMusic;
  exports.faMagnifyingGlass = faMagnifyingGlass;
  exports.faMusic = faMusic;
  exports.faPaperPlane = faPaperPlane;
  exports.faPaste = faPaste;
  exports.faPianoKeyboard = faPianoKeyboard;
  exports.faPlay = faPlay;
  exports.faPlus = faPlus;
  exports.faSdCard = faSdCard;
  exports.faShuffle = faShuffle;
  exports.faSquareQuestion = faSquareQuestion;
  exports.faStop = faStop;
  exports.faSun = faSun;
  exports.faTrashCan = faTrashCan;
  exports.faTriangleExclamation = faTriangleExclamation;
  exports.faVolume = faVolume;
  exports.faVolumeHigh = faVolumeHigh;
  exports.faVolumeLow = faVolumeLow;
  exports.faWaveform = faWaveform;
  exports.faXmark = faXmark;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
